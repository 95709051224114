.container { 
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* background-color: #EBF2FF; */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='900' preserveAspectRatio='none' viewBox='0 0 1440 900'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1045%26quot%3b)' fill='none'%3e%3crect width='1440' height='900' x='0' y='0' fill='rgba(235%2c 242%2c 255%2c 1)'%3e%3c/rect%3e%3cpath d='M22 900L922 0L1019.5 0L119.5 900z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M560.2 900L1460.2 0L2147.2 0L1247.2 900z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M1397 900L497 0L336.5 0L1236.5 900z' fill='url(%23SvgjsLinearGradient1047)'%3e%3c/path%3e%3cpath d='M909.8 900L9.799999999999955 0L-642.7 0L257.29999999999995 900z' fill='url(%23SvgjsLinearGradient1047)'%3e%3c/path%3e%3cpath d='M974.6351542136899 900L1440 434.6351542136899L1440 900z' fill='url(%23SvgjsLinearGradient1046)'%3e%3c/path%3e%3cpath d='M0 900L465.3648457863101 900L 0 434.6351542136899z' fill='url(%23SvgjsLinearGradient1047)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1045'%3e%3crect width='1440' height='900' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='0%25' y1='100%25' x2='100%25' y2='0%25' id='SvgjsLinearGradient1046'%3e%3cstop stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3clinearGradient x1='100%25' y1='100%25' x2='0%25' y2='0%25' id='SvgjsLinearGradient1047'%3e%3cstop stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0'%3e%3c/stop%3e%3cstop stop-opacity='0' stop-color='rgba(15%2c 70%2c 185%2c 0.2)' offset='0.66'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}

.dShadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.bodys {
    padding: 0rem 5rem 0rem 5rem;
    
   
 
    
  }
  
  @media screen and (max-width: 550px) {
    .bodys {   
      padding: 0rem 1.8rem 0rem 1.8rem;
    
    }
  }