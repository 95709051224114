.e7 {
    min-height: 100vh;
   
}

.dot {
    color: #108FF5;
    font-size: 40px;
    padding-right: 10px;
}

.border {
    border-left: 1px #E0E0E0 solid;
}

.TextField-without-border-radius fieldset {
    border-radius: 0;
   
}

.table__header {
    color: #BDBDBD;
    font-weight: bold;

}